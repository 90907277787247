<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout justify-space-between class="d-flex mb-3">
        <span style="align-self: end">{{ items.length }} {{ $t('value') }}</span>
        <div>
          <v-btn color="primary" @click="onShowCreateAccount">
            <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
            {{ $t('account') }}
          </v-btn>
        </div>
      </v-layout>
      <v-data-table
        class="border-primary overflow-y-auto mht-view-all"
        hide-default-footer
        height="80vh"
        fixed-header
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
        :items-per-page="items.length"
        @click:row="onShowUpdateAccount"
      >
        <template v-slot:[`item.email`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.email }}
          </td>
        </template>
        <template v-slot:[`item.clientId`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.clientId }}
          </td>
        </template>
        <template v-slot:[`item.clientSecret`]="{ item }">
          <td class="max-width-4 text-truncate">
            {{ item.clientSecret }}
          </td>
        </template>
        <template v-slot:[`item.transportType`]="{ item }">
          {{ getTextTransportType(item.transportType) }}
        </template>
        <template v-slot:[`item.serviceId`]="{ item }">
          {{ getTextService(item.serviceId) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ getStatusText(item.status) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-menu
            left
            offset-y
            transition="scroll-y-transition"
            content-class="menu-action arrow-up"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-layout column>
                <v-btn
                  block
                  @click="onShowDialogConfirmActiveOrInactiveAccount(item)"
                >
                  {{ getStatusTextAction(item.status) }}
                </v-btn>
                <v-btn
                  v-if="item.status === 0"
                  @click="onClickDeleteAccount(item)"
                >
                  <v-layout align-center style="position: absolute">
                    <span style="display: contents"> {{ getStatusTextActionDelete(item.status) }} </span>
                  </v-layout>
                </v-btn>
              </v-layout>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading :is-show="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />

    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />

    <dialog-create-or-update-account
      ref="dialogConfirmAddAccount"
      v-model="showCreateOrUpdateAccount"
      :is-create="true"
      :item="itemUpdateSelected"
      :partner-name="partnerName"
      :on-accept="onCreateOrUpdateAccount"
      :on-delete="onClickDeleteAccount"
      :on-active="onShowDialogConfirmActiveOrInactiveAccount"
    />

    <!-- confirm add account -->
    <dialog-confirm
      v-model="showDialogConfirmAddAccount"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelAddAccount"
      :on-accept="onAcceptAddAccount"
    />

    <!-- confirm update account -->
    <dialog-confirm
      v-model="showDialogConfirmUpdateAccount"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelUpdateAccount"
      :on-accept="onAcceptUpdateAccount"
    />

    <!-- confirm delete account -->
    <dialog-confirm
      v-model="showDialogConfirmDeleteAccount"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelDeleteAccount"
      :on-accept="onAcceptDeleteAccount"
    />

    <!-- confirm active or inactive account -->
    <dialog-confirm
      v-model="showDialogConfirmActiveOrInactiveAccount"
      :icon-src="getIcon"
      :message="message"
      :text-cancel="textCancel"
      :text-accept="textAccept"
      :on-cancel="onCancelActiveOrInactiveAccount"
      :on-accept="onAcceptActiveOrInactiveAccount"
    />
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import DialogConfirmTokenExpried from '@/components/dialog/DialogConfirmTokenExpried.vue'
import DialogCreateOrUpdateAccount from './components/DialogCreateOrUpdateAccount.vue'
import DialogConfirm from '@/components/dialog/DialogConfirm.vue'
import constants from '@/constants'
import { StorageService } from '@/services/storageService'
import { DateTimeUtils } from '@/helpers/dateTimeUtils.js'
import { StringUtils } from '@/helpers/stringUtils'
import routePaths from '@/router/routePaths'
import { PartnerService } from '@/services/partnerService'

import { mapActions } from 'vuex'

export default {
  components: {
    Breadscrumbs,
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    DialogCreateOrUpdateAccount,
    DialogConfirm
  },
  data() {
    return {
      constants,
      partnerId: parseInt(this.$route.query.id),
      partnerName: null,
      // notifications
      showNoti: false,
      typeIcon: constants.typeAlert.warning,
      message: '',
      showLoading: false,
      showConfirmTokenExpried: false,
      showCreateOrUpdateAccount: false,
      items: [],
      itemUpdateSelected: null,
      textCancel: this.$t('cancel'),
      textAccept: '',
      showDialogConfirmAddAccount: false,
      showDialogConfirmUpdateAccount: false,
      parsCreateOrUpdateAccount: null,
      showDialogConfirmDeleteAccount: false,
      selectedAccountDelete: null,
      showDialogConfirmActiveOrInactiveAccount: false,
      selectedActiveOrInactiveAccount: null,
      useShippingMethod: this.$route.query.useShippingMethod,
      clearData: true,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,

      breadcrumbs: [
        {
          text: this.$t("partner_list"),
          disabled: false,
          isActive: false,
          href: routePaths.PARTNER_LIST
        },
        {
          text: '',
          disabled: true,
          isActive: true,
        }
      ],
    }
  },
  computed: {
    headers() {
      if (this.partnerId === constants.partnerGroup.ninjaVan) {
        return [
          {
            text: `${this.$t('email')} ${this.$t('account').toLowerCase()} `,
            sortable: true,
            value: 'email',
            class: 'text-subtitle-2'
          },
          {
            text: this.$t('id-account'),
            sortable: false,
            value: 'clientId',
            class: 'text-subtitle-2'
          },
          {
            text: this.$t('token-account-code'),
            sortable: false,
            value: 'clientSecret',
            class: 'text-subtitle-2'
          },
          {
            text: this.$t('branch_id'),
            sortable: false,
            value: 'branchId',
            class: 'text-subtitle-2'
          },
          {
            text: this.$t('pick-up-form'),
            value: "transportType",
            class: "text-subtitle-2",
          },
          {
            text: this.$t('ship_service'),
            value: "serviceId",
            class: "text-subtitle-2",
          },
          {
            text: this.$t('status'),
            sortable: false,
            value: 'status',
            class: 'text-subtitle-2'
          },
          {
            text: this.$t('action'),
            sortable: false,
            align: 'center',
            value: 'action',
            class: 't'
          }
        ];
      }
      return [
        {
          text: `${this.$t('email')} ${this.$t('account').toLowerCase()} `,
          sortable: true,
          value: 'email',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('id-account'),
          sortable: false,
          value: 'clientId',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('token-account-code'),
          sortable: false,
          value: 'clientSecret',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('ship_service'),
          value: "serviceId",
          class: "text-subtitle-2",
        },
        {
          text: this.$t('status'),
          sortable: false,
          value: 'status',
          class: 'text-subtitle-2'
        },
        {
          text: this.$t('action'),
          sortable: false,
          align: 'center',
          value: 'action',
          class: 'text-subtitle-2'
        }
      ];
    },
    formatDateTimeHm(datetime) {
      return DateTimeUtils.formatDateTime(datetime, constants.formatDateTimeHm)
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeIcon)
    },
    getTitle() {
      return this.isCreate ? this.$t('add_account') : this.$t('update_account')
    }
  },
  created() {
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN") && !this.currentRole.includes("ROLE_ROOT")) {
      this.$router.go(-1);
    }
    this.partnerId = parseInt(this.$route.query.id)
    this.partnerName = this.$route.query.name
  },
  beforeMount() {
    this.initData()
  },
  methods: {
    ...mapActions('layout', ['setBreadcrumbs']),
    async initData() {
      if (this.partnerId) {
        await this.getSales()
      } else {
        this.$router.push(routePaths.PARTNER_LIST)
      }
      if (this.partnerId === constants.partnerGroup.ninjaVan) {
        this.breadcrumbs[1].text = "list-njv";
      } else {
        this.breadcrumbs[1].text = "list-ghtk";
      }
    },
    async getSales() {
      this.showLoading = true
      const pars = {
        partnerId: this.partnerId,
        poId: StorageService.getPostOfficeId()
      }
      const { status, data } = await PartnerService.getSales(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.items = data
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
      }
    },

    onShowCreateAccount() {
      this.itemUpdateSelected = null
      this.showCreateOrUpdateAccount = true
    },
    onShowUpdateAccount(item) {
      this.itemUpdateSelected = item
      this.showCreateOrUpdateAccount = true
    },
    onCreateOrUpdateAccount(pars) {
      if (pars && pars.saleId) {
        this.message = this.$t('to_save_changes')
        this.textAccept = this.$t('save')
        this.showDialogConfirmUpdateAccount = true
      } else {
        this.message = this.$t('add_link_account')
        this.textAccept = this.$t('add')
        this.showDialogConfirmAddAccount = true
      }
      this.parsCreateOrUpdateAccount = pars
    },
    onCancelAddAccount() {
      this.parsCreateOrUpdateAccount = null
      this.showDialogConfirmAddAccount = false
    },
    async onAcceptAddAccount() {
      if (this.parsCreateOrUpdateAccount) {
        this.showDialogConfirmAddAccount = false
        this.showLoading = true
        this.$refs.dialogConfirmAddAccount.clearData();
        const pars = this.parsCreateOrUpdateAccount
        const { status, data } = await PartnerService.createAccount(pars)
        this.showLoading = false
        this.itemUpdateSelected = null
        this.showCreateOrUpdateAccount = false
        if (status === constants.statusCode.ok && data) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t("success-create-account-link"),
          });
          setTimeout(() => {
            this.toggleDialogNoti();
            this.getSales();
          }, constants.timeOut);
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    onCancelUpdateAccount() {
      this.parsCreateOrUpdateAccount = null
      this.showDialogConfirmUpdateAccount = false
    },
    async onAcceptUpdateAccount() {
      if (this.parsCreateOrUpdateAccount) {
        this.showDialogConfirmUpdateAccount = false
        this.showLoading = true
        this.$refs.dialogConfirmAddAccount.clearData();
        const pars = this.parsCreateOrUpdateAccount
        const { status, data } = await PartnerService.updateSaleNinjavan(pars)
        this.showLoading = false
        this.itemUpdateSelected = null
        this.showCreateOrUpdateAccount = false
        if (status === constants.statusCode.ok && data) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t("success-edit-account-link"),
          });
          setTimeout(() => {
            this.toggleDialogNoti();
            this.getSales();
          }, constants.timeOut);
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    getTextPriceType(val) {
      return val === constants.pricesType.standard
        ? this.$t('standard_price')
        : this.$t('weight_price')
    },
    getTextTransportType(val) {
      return val === constants.transportType.ship
        ? this.$t('shipper_pickup')
        : this.$t('customer_bring_order')
    },
    getStatusText(val) {
      return val === constants.statusPartnerAccount.active
        ? this.$t('activated')
        : this.$t('inactived')
    },
    getStatusTextAction(val) {
      return val === constants.statusPartnerAccount.active
        ? this.$t('inactived')
        : this.$t('activated')
    },
    getStatusTextActionDelete(val) {
      if (val !== constants.statusPartnerAccount.active) {
        return this.$t('delete')
      }
    },
    getTextService(val) {
      if (val === constants.servicesShipping.normal) {
        return this.$t('normal')
      } else if (val === constants.servicesShipping.express) {
        return this.$t('fast')
      } else if (val === constants.servicesShipping.standard) {
        return this.$t('normal')
      } else {
        return this.$t('over')
      }
    },
    getColorAction(val) {
      return val === constants.statusPartnerAccount.active ? 'error' : 'primary'
    },
    getStatusByItem(i) {
      return i.status === constants.statusPartnerAccount.active
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: '' }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    },
    onClickDeleteAccount(item) {
      if (item.status === constants.statusPartnerAccount.active) {
        this.toggleDialogNoti({
          state: true,
          msg: this.$t('cannot_deleted')
        })
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      } else {
        this.textAccept = this.$t('delete')
        this.message = this.$t('delete_partner_account')
        this.showDialogConfirmDeleteAccount = true
        this.selectedAccountDelete = item
      }
    },
    onCancelDeleteAccount() {
      this.showDialogConfirmDeleteAccount = false
      this.selectedAccountDelete = null
    },
    async onAcceptDeleteAccount() {
      this.showDialogConfirmDeleteAccount = false
      this.showCreateOrUpdateAccount = false
      const item = this.selectedAccountDelete
      this.showLoading = true
      const pars = { id: item.id }
      const { status, data } = await PartnerService.deleteSaleNinjavan(pars)
      this.showLoading = false
      this.selectedAccountDelete = null
      if (status === constants.statusCode.ok && data) {
        this.getSales()
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
        this.getSales()
      }
    },
    onShowDialogConfirmActiveOrInactiveAccount(item) {
      if (item.status) {
        this.textAccept = this.$t('inactived')
        this.message = this.$t('inactive_link_account')
      } else {
        this.textAccept = this.$t('activated')
        this.message = this.$t('active_link_account')
      }

      this.showDialogConfirmActiveOrInactiveAccount = true
      this.selectedActiveOrInactiveAccount = item
    },
    onCancelActiveOrInactiveAccount() {
      this.showDialogConfirmActiveOrInactiveAccount = false
      this.selectedActiveOrInactiveAccount = null
    },
    onAcceptActiveOrInactiveAccount() {
      if (this.selectedActiveOrInactiveAccount) {
        const item = this.selectedActiveOrInactiveAccount
        if (item.status) {
          this.handlerDeactiveAccount(item.id)
        } else {
          this.handlerActiveAccount(item.id)
        }
      }
    },
    async handlerActiveAccount(id) {
      this.onCancelActiveOrInactiveAccount()
      this.showLoading = true
      const pars = { id: id }
      const { status, data } = await PartnerService.activeSaleNinjavan(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("msg_success_active_account"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          this.getSales();
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      }
    },
    async handlerDeactiveAccount(id) {
      this.onCancelActiveOrInactiveAccount()
      this.showLoading = true
      const pars = { id: id }
      const { status, data } = await PartnerService.deactiveSaleNinjavan(pars)
      this.showLoading = false
      if (status === constants.statusCode.ok && data) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("msg_success_deactive_account"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          this.getSales();
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message })
        setTimeout(() => {
          this.toggleDialogNoti()
        }, constants.timeOut)
      }
    }
  }
}
</script>
<style src="@/styles/postOffice.scss" lang="scss">
</style>
